<template>
<div class="recipient-address">
    <div class="recipient-address-item">
        <span class="recipient-address-title">{{itemInfo.title}}</span><br/> 
        <span v-html="itemInfo.content"></span>
    </div>
</div>
</template>

<script>
export default {
    name: 'RecipientAddress',
    components: {},
    props: {
        itemInfo: Object
    },
}
</script>

<style lang="scss">
.recipient-address {
    margin-top: .08rem;
    font-size: .13rem;
    font-family: PingFang SC;
    color: rgba(19, 22, 27, 1);
    font-weight: 400;
    line-height: .28rem;
    .recipient-address-item {
        .recipient-address-title {
            font-weight: bold;
        }
    }
}
</style>