<template>
<div class="invoice-info">
    <div class="invoice-info-item">
        <span class="invoice-info-title">发票抬头</span><br/> 
        {{itemInfo.invoiceRise}}
    </div>
    <div class="invoice-info-item">
        <span class="invoice-info-title">纳税人登记号</span><br/> 
        {{itemInfo.invoiceRegister}}
    </div>
</div>
</template>

<script>
export default {
    name: 'InvoiceInfo',
    components: {},
    props: {
        itemInfo: Object
    },
}
</script>

<style lang="scss">
.invoice-info {
    margin-top: .2rem;
    font-size: .13rem;
    font-family: PingFang SC;
    color: rgba(19, 22, 27, 1);
    font-weight: 400;
    line-height: .28rem;
    .invoice-info-item {
        border-bottom: 1px solid rgba(245,245,245,1);
        padding: .04rem 0;

        .invoice-info-title {
            font-weight: bold;
        }
    }
}
</style>