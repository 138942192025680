<template>
    <div class="customer-service">
        <div class="hearderBox">
            <span @click="backPath">
                <template v-if="isWxWork">返回</template>
                <Svgs v-else name="iconfanhui" color="#000" />
            </span> 
        联系客服
        </div>
        <!-- <PublicTitle @back-call="backPath" title="联系客服"/> -->
        <div class="customer-service-content">
            <component
                v-for="(item, index) in componentList"
                :key="index"
                :is="item.componentName"
                :itemInfo="item"
            ></component>

            <Skeleton v-if="isFirst" :skList="skList"></Skeleton>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import HotLine from "./customerService/HotLine";
import ReceivingQR from "./customerService/ReceivingQR";
import InvoiceInfo from "./customerService/InvoiceInfo";
import RecipientAddress from "./customerService/RecipientAddress";
import { SmartStorage } from 'smart-core-util'

export default {
    name: "CustomerService",
    components: {
        HotLine,
        ReceivingQR,
        InvoiceInfo,
        RecipientAddress,
    },
    data() {
        return {
            isWxWork: window.navigator.userAgent.toLowerCase().indexOf('wxwork') !== -1,
            isFirst: true,
            componentList: [],
            skList: [
                {
                    width: "100%",
                    height: "1.2rem",
                    mt: ".3rem",
                },
                {
                    width: "100%",
                    height: "1rem",
                    mt: ".3rem",
                },
                {
                    width: "50%",
                    height: ".3rem",
                    mt: ".3rem",
                },
                {
                    width: "100%",
                    height: ".3rem",
                },
                {
                    width: "70%",
                    height: ".3rem",
                    mt: ".3rem",
                },
                {
                    width: "100%",
                    height: ".3rem",
                },
            ],
        };
    },
    created() {
        this.getCustomerServiceInfo();
    },
    computed: {
        // tenant
        needTenantId() {
            let tenant = this.$cookies.get("tenant") || '';
            return tenant == 'crossevent'
        }
    },
    methods: {
        async getCustomerServiceInfo() {
            let tenant = this.$cookies.get("tenant") || SmartStorage.get("tenant");
            console.log(this.$cookies.get("tenant"))
            console.log(SmartStorage.get("tenant"))
            const params = {
                collection: "cfg-ihelper-wiki",
                filter: {
                    tenantCode: tenant,
                },
                projection: {
                    componentList: 1,
                },
            };
            if(this.needTenantId) {
                params.filter.tenantId = this.$cookies.get("tenant_id")
            }
            let _this = this;
            this.services.GetSettings(params).then(function (res) {
                if (res.success) {
                    _this.isFirst = false;
                    _this.componentList = res.content.componentList;
                }
            });
        },
        backPath() {
            this.iJsBridge.close();
        },
    },
};
</script>

<style lang="scss" scoped>
.customer-service {
    // padding-top: 1rem;
    .customer-service-content {
        padding: 0.1rem;
    }
}
</style>
